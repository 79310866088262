import { Component, OnChanges, SimpleChanges, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons/faShoppingBasket';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http.service';
import { PaymentService } from 'src/app/services/payment.service';
import { MemoryStorage } from 'src/app/services/memory-storage.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    templateUrl: './geronigo-vouchers.component.html',
})
export class GeronigoVouchersComponent implements OnInit, OnChanges, OnDestroy {
    @Input() brandId: string;
    @Input() venueId: string;
    @Input() saleId: string;
    @Input() guestKey: string;
    @Input() cart = '0';
    @Input() curr: string;
    @Input() opened: boolean = false;

    @Input() fbad: string;
    @Input() gclid: string;
    @Input() bingad: string;
    @Input() affiliateId: string;
    @Input() adId: string;
    @Input() test: string;
    @Input() theme: string;
    @Output() initialised: EventEmitter<void> = new EventEmitter();

    public faGiftCard = faGift;
    public faShoppingBasket = faShoppingBasket;

    public viewBasket = [];

    public loading = true;
    public setCartLoader = false;
    public amount = new UntypedFormControl(25, Validators.required);
    public company: any = null;
    public directories: any = {};
    public presets: any[] = [];
    public hint = '';

    public bindHtml = '<b>meh</b><script language="javascript">console.log("a");</script>';

    private onVoucherDeleted = this.deleteVoucher.bind(this);
    private _voucherConfig: any = null;
    private _images: string[] = [];

    public sale: any;

    public inited : boolean = false;;

    public modalDisplayed = false;

    constructor(
        private http: HttpService,
        private payment: PaymentService,
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private memoryStorage: MemoryStorage,
        private sanitized: DomSanitizer
    ) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['dataLayer'] = window['dataLayer'] || [];

        this.translate.setDefaultLang('en');
    }

    ngOnInit() {
        let test = new URLSearchParams(window.location.search).get('test');
            if(test!==undefined && test !== null){
            this.test = +test+'';
            if(test === 'true') this.test = '1';
            if(this.test === 'NaN' || this.test === '0') this.test = '';
             if(this.test) this.http.mode = 'test';
        }

        let venueId = new URLSearchParams(window.location.search).get('venue_id');
        if(+venueId) this.venueId = venueId;
        
        let brandId = new URLSearchParams(window.location.search).get('brand_id');
        if(+brandId) this.brandId = brandId;

        let saleId = new URLSearchParams(window.location.search).get('sale_id');
        if(+saleId) this.saleId = saleId;

        let guestKey = new URLSearchParams(window.location.search).get('guest_key');
        if(guestKey) this.guestKey = guestKey;

        this.viewBasket = JSON.parse(this.memoryStorage.getItem('vouchers')) || [];
        try{
            this.viewBasket = JSON.parse(localStorage.getItem('vouchers')) || this.viewBasket;
        }catch{}

        this.amount.disable();

        if (this.apiUrl && this.appKey && this.apiKey) this.getData();

        window.addEventListener('voucherDeleted', this.onVoucherDeleted);

        if(this.opened){
            this.addToCart ={'qty':1, 'title':'test', 'amount':10};
            this.showBasket();
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.lang && changes.lang.currentValue) this.translate.use(changes.lang.currentValue);
    }

    ngOnDestroy() {
        window.removeEventListener('voucherDeleted', this.onVoucherDeleted);
    }

    @Input()
    set voucherConfig(values: any) { this._voucherConfig = JSON.parse(values); }
    get voucherConfig() { return this._voucherConfig };

    @Input()
    set images(values: any) { this._images = JSON.parse(values); }
    get images() { return this._images };

    @Input()
    set apiUrl(url: string) { this.http.baseURL = url; }
    get apiUrl(): string { return this.http.baseURL; }

    @Input()
    set appKey(val: string) { this.http.appKey = val; }
    get appKey(): string { return this.http.appKey; }

    @Input()
    set apiKey(val: string) { this.http.apiKey = val; }
    get apiKey(): string { return this.http.apiKey; }

    @Input()
    set mode(val: 'test'|'live') { this.http.mode = val; }
    get mode(): 'test'|'live' { return this.http.mode; }

    @Input()
    set lang(val: string) { this.http.lang = val; }
    get lang(): string { return this.http.lang; }

    @Input()
    set openCart(cart: boolean) {
        this.showBasket();
    }

    @Input()
    set addToCart(data: { voucherpreset_id?: string, amount: number, qty: number, image?: string, title?: string, hideprice?:boolean, wrap_option_id?:number,  }) {
        if (!data) return;

        data.amount = +data.amount;
        data.qty = +data.qty;

        

        if(!this.inited){
            setTimeout(()=>this.addToCart = data, 300);
            return;
        }


        let preset = undefined;
        if (data?.voucherpreset_id) {
            const item = this.presets.find(val => +val.voucherpreset_id === +data.voucherpreset_id);

            if (!item) return;

            this.selectPreset(item, +data.qty || 1, true);
            return;
        }

        const item = this.directories.wrap_options.find(val => val.tag === 'email');

        let voucher_obj :any = {
            value: +data.amount || +this.amount.value,
            wrap_option_id: item?.wrap_option_id,
            mail_service_id: '1',
            preset: preset,
            name: null,
            message: null,
            title:data.title || null,
            delivery_name: '',
            address_1: '',
            address_2: '',
            town:'',
            zip:'',
            tag: item?.tag || 'email',
            image: data?.image,
            design: data?.image || this.company.image || this.company?.images[0].full_url
        }

        if(data?.hideprice){
            voucher_obj.hideprice = true;
        }

        let datas = [...Array(data.qty || 1).fill(voucher_obj)];

        this.viewBasket = [
            ...datas,
            ...this.viewBasket
        ];

        
        let modal = this.showBasket(); //show basket straightaway, and then delay adding things.
        setTimeout(()=>{
            datas.map(it=>modal.addToCart = it);
            }
            ,200);
     }

     set setCart(vouchers: {[key:string]:any}[]) {
        if (!vouchers) return;

        /*
        if(!this.inited){
            setTimeout(()=>this.addToCart = data, 300);
            return;
        }*/


        this.viewBasket = [
            ...vouchers
        ];

        
        let modals = document.getElementsByTagName('basket-modal'); //show basket straightaway, and then delay adding things.
        console.log(modals);
        let modal: any;
        if(modals.length > 0){
            modal = modals[0];
        }else{
            if(!this.setCartLoader){
                this.showBasket();
                this.setCartLoader = true;
            }
            setTimeout(()=>this.setCart = vouchers,1000);
            return;
        }
        setTimeout(()=>{
            vouchers.map(it=>{
                it.value = parseFloat(it.value);
                modal.addToCart = it;});
            },100);
     }

    public get currency(): string {
        return getCurrencySymbol(this.company.currency || 'USD', 'narrow', 'en-GB');
    }

    public get paymentsSetup(): boolean {
        return this.payment.initialized;
    }

    public openModal(amount?: number, qty = 1, show = true, design = null, title = null): void {
       this.addToCart = { amount, qty, image:design, title };
    }


    public selectPreset(item: any, qty = 1, show = true): void {
        const wrapOption = this.directories.wrap_options.find(val => val.tag === 'email');
        this.viewBasket = [
            ...Array(qty).fill({
                value: +item.price,
                wrap_option_id: wrapOption.wrap_option_id,
                tag: wrapOption.tag,
                image: item.image_url,
                preset: item,
                mail_service_id: '1',
                name: '',
                message: '',
                address: ''
            }),
            ...this.viewBasket
        ];

        this.memoryStorage.setItem('vouchers', JSON.stringify(this.viewBasket));
        try{
            localStorage.setItem('vouchers', JSON.stringify(this.viewBasket));
        }catch{

        }

        this.showBasket(qty, show);
    }

    public showBasket(qty = 1, message = false, retry=true) {
        if(!this.inited || !this.paymentsSetup){
            if(retry){
                setTimeout(()=>this.showBasket(qty, message),500);
            }
            return;
        }
        const elem = document.createElement('basket-modal') as any;

        elem.brandId = this.brandId;
        elem.qty = qty;

        if(this.sale) elem.sale = this.sale;

        if (+this.cart) elem.message = message;

        document.body.appendChild(elem);

        setTimeout(()=>this.modalDisplayed = true,0);
        this.checkModal();
        return elem;
    }

    public checkModal(){
        if(document.getElementsByTagName('basket-modal').length  == 0){
            setTimeout(()=>this.modalDisplayed = false,0);
            return; 
        }

        setTimeout(()=>this.checkModal(),500);
    }

    public editVoucherValue(value: number): void {
        const val = this.amount.value + value;

        if (val <= this.company.voucher_config.max && val >= this.company.voucher_config.min) this.amount.setValue(val);

        this.checkHint();
    }

    private getData(): void {
        combineLatest([
            this.http.get('/directories', {
                params: {
                    directories: 'pay_profile,wrap_options,sale_types,mail_services,texts'
                }
            }),
            this.http.get('/companies', {
                params: { company_id: 'self' },
                headers: { 'X-ATTRIBUTES': 'country,currency,rules,voucher_config,company_id,voucher_valid' }
            }),
            this.http.get('/images', {
                params: { company_id: 'self', type: 'logo' }
            }),
            this.http.get('/voucherpresets', {
                params: { company_id: 'self', limit: '500' }
            }),
            !this.saleId?of(null):this.http.get('/sales', {
                headers:{'X-API-KEY':this.guestKey},
                params: { sale_id: this.saleId }
            }),
            !this.brandId ?of(null):this.http.get('/brands', {
                params: { company_id: 'self', brand_id:this.brandId}
            }),

        ]).pipe(
            tap(([dirRes, compRes, logoRes, presetsRes, saleRes, brandRes]) => {
                let logos = logoRes.body?.images || [];
                if(logos.length > 1){
                    logos = logos.filter(it=>it.brand_id == this.brandId);
                    if(logos.length == 0) logos = logoRes.body?.images || [];
                }
                let logo = logos[0];

                if(brandRes?.body?.brands && brandRes?.body?.brands[0].logo){
                    logo = {filename:'https://rezbot.com/images/logos/'+brandRes?.body?.brands[0].logo};
                }

                this.directories = dirRes.body;
                
                if (presetsRes) this.presets = presetsRes.body?.voucherpresets || [];
                this.presets.forEach(preset=>preset.safe_desc = this.sanitized.bypassSecurityTrustHtml(preset.desc))


                let company: any = {};
                if(compRes && compRes.body?.companies){
                    company = compRes.body?.companies[0];
                }

                let logo_url = logo?.filename;

                if(logo_url && logo_url.indexOf('://') < 0){
                    logo_url = `${this.directories.resources.images_path}/images/rezbot/*by200/${logo?.filename}`
                }

                this.company = {
                    ...company,
                    currency: this.curr || company.currency,
                    logo: logo_url
                };
                
                this.company.voucher_config = this.voucherConfig || this.company.voucher_config;
                
                this.memoryStorage.setItem('directories', JSON.stringify(dirRes.body));

                this.memoryStorage.setItem('params', JSON.stringify({fbad: this.fbad, gclid:this.gclid, bingad:this.bingad, affiliateId:this.affiliateId, adId:this.adId, test:this.test}));

                this.memoryStorage.setItem('company', JSON.stringify(this.company)); //first store

                if(saleRes?.body){
                    this.sale = saleRes.body.sales[0];

                    this.memoryStorage.removeItem('vouchers');

                    this.memoryStorage.removeItem('vouchers');
                    try{
                        localStorage.removeItem('vouchers');
                    }catch{

                    }

                    this.setCart =this.sale.vouchers;
                    //this.sale.vouchers.forEach(it=>this.addToCart =it)

                    //setTimeout(()=>this.showBasket(0,false),200);
                }
                
                this.amount.setValue(this.company?.voucher_config?.displayValue);
            	this.checkHint();
            }),
            switchMap(() => {
                //return this.http.get('/images', { params: { caption: `wrap_option:${this.company.company_id}:email` } });
                return this.http.get('/images', { params: { type:'voucher',company_id:'self', limit:'200' } });
            }),
            finalize(() => this.loading = false)
        )
        .subscribe(res => {

            if (this.images?.length) {


                if(this.images[0].full_url === undefined){
                    this.company.image = this.images[0];
                    this.company.images = this.images.map(img=>({full_url:img}));    
                }else{
                    this.company.image = this.images[0].full_url;
                    this.company.images = this.images;
                }
            } else {
                const img = (res.body?.images||[]).find(item => +item.def === 1);

                //this.company.image = img ? `${this.directories.resources.images_path}/images/rezbot/600by450/${img.filename}` : null;
                //this.company.image = img ? `${this.directories.resources.images_path}/images/rezbot/300by250/${img.filename}` : null;
                let filtered = res.body?.images || [];
                filtered = filtered.filter(item=>(!this.brandId || this.brandId != '0') && this.brandId == item.brand_id);

                if(filtered.length == 0){
                    filtered = res.body?.images||[];
                }
                if(this.venueId){
                    let old_filtered = filtered;
                    filtered = filtered.filter(it=>!+it.venue_id || it.venue_id == this.venueId);
                    if(filtered.length == 0){
                        filtered = old_filtered;
                    }
                }

                this.company.images = filtered.map(item => ({...item,
                     full_url:`${this.directories.resources.images_path}/images/rezbot/600by450/${item?.filename}`
                }));
            }

            this.memoryStorage.setItem('company', JSON.stringify(this.company));

            this.payment.currency = this.company.currency;
            if(!this.inited){
                let options:{[key:string]:any} = {};
                if(this.brandId == '17' && this.company.currency == 'GBP'){
                    options.paylater = true;
                }else{
                    options.paylater = false;
                }
                this.payment.init(null, this.directories.pay_profile, options);
            }

            this.cd.detectChanges();
            this.initialised.emit();
            setTimeout(()=>{
                this.inited = true
            });
        });
    }

    private deleteVoucher(): void {
        this.viewBasket.splice(0, 1);
    }

    private checkHint(): void {
        if (!this.company.voucher_config || !this.company.voucher_config.hints) return;

        const hint = this.company.voucher_config.hints.find(item => item.val === this.amount.value);

        if (hint) this.hint = hint.text;
        else this.hint = '';
    }
}
