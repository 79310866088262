import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

import { loadScript } from "@paypal/paypal-js";

import { Output, EventEmitter } from '@angular/core';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public paypalLoaded: EventEmitter<string> = new EventEmitter();
    public stripeLoaded: EventEmitter<string> = new EventEmitter();

    public stripeKey: string;
    public paypalKey: string;
    public worldpayKey: string;
    public paypalEnv: string;
    public stripe: any;
    public paypal: any;
    public elements: any;
    public secure = false;
    public initialized = false;
    public fees: { min: number, fee: number }[] = [];
    public profiles: string[] = [];
    public currency = 'GBP';

    constructor(
        private http: HttpService
    ) { }

    public init(p: string, res: any, options: any): void {
        this.initialized = true;

        const version = this.http.mode;
        const threshold = { ...res.booking_fee.threshold };
        const type = p || 'default';

        const profiles = res[type][version];

        this.fees = Object.keys(threshold).map(min => ({ min: +min, fee: +threshold[min] }));
        this.profiles = profiles.map(item => item.processor);
        this.paypalEnv = this.http.mode === 'test' ? 'sandbox' : 'production';

        if(this.http.isTestMode) console.log('payments in test mode');

        if (this.profiles.includes('stripe')) {
            const stripe = profiles.find(item => item.processor === 'stripe');

            this.stripeKey = stripe.key;
            this.secure = stripe['3d_secure'];

            if(typeof(Stripe) === "undefined"){
                this.loadScript(`https://js.stripe.com/v3/`,function(){
                    this.stripe = Stripe(this.stripeKey);
                    this.elements = this.stripe.elements();
                    this.stripeLoaded.emit('loaded');
                }.bind(this));
            }else{
                this.stripe = Stripe(this.stripeKey);
                this.elements = this.stripe.elements();
                this.stripeLoaded.emit('loaded');
            }
        }

        if (this.profiles.includes('paypal')) {
            const paypal = profiles.find(item => item.processor === 'paypal');

            let extra_options:{[key:string]:string} = {"disable-funding":'card,bancontact,credit'};
            //below is disabled for now
            if(options.paylater){ 
                //extra_options = {"disable-funding":'card,bancontact','enable-funding':'paylater'}
                extra_options = {"disable-funding":'card,bancontact','enable-funding':'paylater'}
                //extra_options = {'enable-funding':'paylater'}
            }

            this.paypalKey = paypal.key;
            setTimeout(()=>{
                loadScript({
                 ...extra_options   ,
                 "client-id":this.paypalKey,
                "components":"messages,buttons",
                "currency":this.currency,
                "intent":"authorize"})
                    .then((paypal)=>{
                        this.paypalLoaded.emit('loaded');
                        this.paypal = paypal
                    })
                    .catch((err)=>{console.log('error-paypal'+JSON.stringify(err))})
                },50);

            /*this.loadScript(
                `https://www.paypal.com/sdk/js?client-id=${this.paypalKey}&disable-funding=card,credit,bancontact&currency=${this.currency}&intent=authorize`,
                ()=>{console.log('paypal loaded')},
                (err)=>{console.log('error'+JSON.stringify(err))}
            );*/
        }
    }

    private loadScript(url: string, onload: Function|null=null, onerror: Function|null=null) {
        if (document.querySelector(`script[src="${url}"]`)) return;

        const script = document.createElement('script') as any;

        script.type = 'text/javascript';
        script.src = url;
        script.referrerpolicy = "same-origin"
        script.crossorigin='crossorigin';

        if(onload){
            script.onload = onload;
        }
        if(onerror){
            script.onerror = onerror;
        }

        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
